import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ScreenModal from '../../components/common/screen-modal';
import Header from './header-progress';
import Container from 'react-bootstrap/Container';
import { useHistory } from 'react-router-dom';
import { ProgressViewModes } from '../../components/order/progress/progress-view-modes';
import { toastError } from '../../helpers/packing/packingHelper';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '@naadi/framework';
import {
  downloadProgress,
  getOperationsInitData,
  getRecentOrdersList,
  GROUPS,
} from '../../helpers/order/progressHelper';
import { ProgressContainer } from '../../components/order/progress/progress-container';
import { ToastContainer } from 'react-toastify';
import { getOrderProgress } from '../../services/order-operation-stat';
import ScreenLoader from '../../components/common/screen-loader';
import { setOrderProgressCompleted } from '../../store/order/orderstate/orderstate';

const ORDER_FETCH_ERROR = "Unable to fetch the order's progress";
const OrderProgress = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [operations, setOperations] = useState([]);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const {
    order_progress_group,
    order_progress_group2,
    order_progress_group3,
    order_progress_item_type,
    order_progress_completed,
  } = useSelector(state => state.orderstate);
  const [progressList, setProgressList] = useState([]);
  const [progressDisplayList, setProgressDisplayList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const progressFetch = useRef({
    fetching: false,
    count: 0,
    group: order_progress_group,
    group2: order_progress_group2,
    item_type: order_progress_item_type,
  });
  const getProgress = useCallback(async () => {
    if (operations.length === 0) {
      setProgressList([]);
      return;
    }
    if (progressFetch.current.fetching) {
      if (
        progressFetch.current.group !== order_progress_group ||
        progressFetch.current.item_type !== order_progress_item_type ||
        progressFetch.current.group2 !== order_progress_group2 ||
        progressFetch.current.group3 !== order_progress_group3
      ) {
        progressFetch.current.group = order_progress_group;
        progressFetch.current.group2 = order_progress_group2;
        progressFetch.current.group3 = order_progress_group3;
        progressFetch.current.item_type = order_progress_item_type;
        progressFetch.current.count++;
        setProgressList([]);
      } else {
        return;
      }
    }
    const fetchCount = progressFetch.current.count;
    let skipFetchReset = false;
    try {
      progressFetch.current.fetching = true;
      progressFetch.current.group = order_progress_group;
      progressFetch.current.group2 = order_progress_group2;
      progressFetch.current.group3 = order_progress_group3;
      progressFetch.current.item_type = order_progress_item_type;
      const ordersRes = await getRecentOrdersList(
        order_progress_group,
        order_progress_group2,
        order_progress_group3,
        order_progress_item_type,
        searchTerm,
      );
      if (fetchCount !== progressFetch.current.count) {
        skipFetchReset = true;
        return;
      }
      if (ordersRes.status !== 200) {
        setProgressList([]);
        toastError(ORDER_FETCH_ERROR);
        return;
      }
      progressFetch.current.count++;
      setProgressList(ordersRes.payload);
    } finally {
      if (skipFetchReset === false) {
        progressFetch.current.fetching = false;
      }
    }
  }, [
    order_progress_group,
    order_progress_group2,
    order_progress_group3,
    order_progress_item_type,
    operations,
    searchTerm,
  ]);
  useEffect(() => {
    const fn = async () => {
      try {
        dispatch(actions.busy.add('INIT_ORDER_PROGRESS'));
        const _operations = await getOperationsInitData();
        if (!_operations) {
          toastError('Unable to Initialize. Please try again');
          return;
        }
        setOperations(_operations);
      } finally {
        dispatch(actions.busy.remove('INIT_ORDER_PROGRESS'));
      }
    };
    fn();
  }, [dispatch, setOperations]);
  useEffect(() => {
    getProgress();
  }, [getProgress, refreshTrigger]);
  useEffect(() => {
    const list = [];
    const getChildren = (parent, level) => {
      const _list = [];
      if (parent.children && parent.children.length > 0) {
        parent.children.forEach(child => {
          child.level = level + 1;
          _list.push(child);
          getChildren(child, level + 1).forEach(_child => {
            _list.push(_child);
          });
        });
      }
      return _list;
    };
    const operationCodes = operations.map(val => val.operation_code);
    progressList.forEach(val => {
      val.level = 0;
      let completed = !searchTerm || searchTerm.length === 0;
      operationCodes.forEach(operationCode => {
        if (
          completed === true &&
          val.operation_stats &&
          val.operation_stats[`${operationCode}`]
        ) {
          const operationStat = val.operation_stats[`${operationCode}`];
          if (operationStat.total_pending_qty > 0) {
            completed = false;
          }
        }
      });
      if (completed === true) {
        return;
      }
      list.push(val);
      getChildren(val, 0).forEach(cVal => {
        list.push(cVal);
      });
    });
    setProgressDisplayList(list);
  }, [searchTerm, progressList, operations, setProgressDisplayList]);
  const updateChildOrder = useCallback((list, orderStat, children) => {
    const _list = [...list];
    for (let i = 0; i < _list.length; i++) {
      const val = _list[`${i}`];
      if (val.group === orderStat.group && val.key === orderStat.key) {
        val.children = children;
        return _list;
      }
      if (val.children && val.children.length > 0) {
        const _children = updateChildOrder(val.children, orderStat, children);
        if (_children) {
          val.children = _children;
          return _list;
        }
      }
    }
    return null;
  }, []);
  /* eslint-disable sonarjs/cognitive-complexity */
  const onDetailCb = useCallback(
    async orderStat => {
      try {
        dispatch(actions.busy.add('ORDER_PROGRESS_ON_DETAIL'));
        let childOrders = [];
        let group =
          orderStat.group2 &&
          orderStat.group2 !== 'NONE' &&
          orderStat.level <= 0
            ? orderStat.group2
            : orderStat.group;
        if (
          group &&
          group === orderStat.group2 &&
          orderStat.group2 !== 'NONE' &&
          orderStat.group2 !== ''
        ) {
          switch (group) {
            case GROUPS.ORDER_EXTERNAL_REFERENCE.key:
              if (!orderStat.ext_code || orderStat.ext_code === '') {
                group = orderStat.group;
              }
              break;
            case GROUPS.SECONDARY_CUSTOMER.key:
              if (
                !orderStat.secondary_customer ||
                orderStat.secondary_customer === ''
              ) {
                group = orderStat.group;
              }
              break;
            default:
          }
        }

        if (orderStat.children && orderStat.children.length > 0) {
          childOrders = [];
        } else if (group === GROUPS.ORDER.key) {
          const ordersRes = await getOrderProgress(
            'ORDER_ITEM',
            GROUPS.NONE.key,
            GROUPS.NONE.key,
            new Date(0),
            orderStat.order.uuid,
            group,
            orderStat.item_type,
            2000,
          );
          if (ordersRes.status !== 200) {
            toastError('Unable to Fetch the Order Items');
            return;
          }
          const payload = ordersRes.payload;
          childOrders = payload;
        } else if (group === GROUPS.PARENT_ORDER.key) {
          const ordersRes = await getOrderProgress(
            GROUPS.ORDER.key,
            GROUPS.NONE.key,
            GROUPS.NONE.key,
            new Date(0),
            orderStat.order.uuid,
            group,
            orderStat.item_type,
            300,
          );
          if (ordersRes.status !== 200) {
            toastError('Unable to Fetch the Child Orders');
            return;
          }
          childOrders = ordersRes.payload;
        } else if (group === GROUPS.ORDER_EXTERNAL_REFERENCE.key) {
          const ordersRes = await getOrderProgress(
            GROUPS.PARENT_ORDER.key,
            GROUPS.NONE.key,
            GROUPS.NONE.key,
            new Date(0),
            orderStat.order.ext_code,
            group,
            orderStat.item_type,
            300,
          );
          if (ordersRes.status !== 200) {
            toastError(ORDER_FETCH_ERROR);
            return;
          }
          childOrders = ordersRes.payload;
        } else if (group === GROUPS.CUSTOMER.key) {
          const ordersRes = await getOrderProgress(
            GROUPS.PARENT_ORDER.key,
            GROUPS.NONE.key,
            GROUPS.NONE.key,
            new Date(0),
            orderStat.order.entity_id,
            group,
            orderStat.item_type,
            300,
          );
          if (ordersRes.status !== 200) {
            toastError(ORDER_FETCH_ERROR);
            return;
          }
          childOrders = ordersRes.payload;
        } else if (group === GROUPS.SECONDARY_CUSTOMER.key) {
          const ordersRes = await getOrderProgress(
            GROUPS.PARENT_ORDER.key,
            GROUPS.NONE.key,
            GROUPS.NONE.key,
            new Date(0),
            orderStat.order.secondary_customer,
            group,
            orderStat.item_type,
            300,
          );
          if (ordersRes.status !== 200) {
            toastError(ORDER_FETCH_ERROR);
            return;
          }
          childOrders = ordersRes.payload;
        } else if (group === 'ORDER_ITEM') {
          return;
        }

        childOrders = childOrders.filter(child => child.key !== orderStat.key);

        setProgressList(prevList => {
          const _list = updateChildOrder(prevList, orderStat, childOrders);
          if (!_list) {
            return prevList;
          }
          return _list;
        });
      } finally {
        dispatch(actions.busy.remove('ORDER_PROGRESS_ON_DETAIL'));
      }
    },
    [dispatch, setProgressList, updateChildOrder],
  );
  /* eslint-nebale sonarjs/cognitive-complexity */
  return (
    <Fragment>
      <div className='height-100-percent width-100-percent overflow-hidden'>
        <ScreenModal />
        <ScreenLoader />
        <Header history={history} title={'Order Progress'} />
        <div className='content bg-white' style={{ padding: '16px' }}>
          <Container fluid>
            <div className='bg-white' style={{ zIndex: '1' }}>
              <ProgressViewModes
                filterCb={setSearchTerm}
                order_progress_completed={order_progress_completed}
                onProgressCompletedChange={() => {
                  dispatch(
                    setOrderProgressCompleted(
                      order_progress_completed !== true,
                    ),
                  );
                }}
                onDownloadCb={() => {
                  downloadProgress({
                    group: order_progress_group,
                    group2: order_progress_group2,
                    group3: order_progress_group3,
                    progressList: progressDisplayList,
                    operations: operations,
                  });
                }}
              />
            </div>
            <ProgressContainer
              order_progress_completed={order_progress_completed}
              group={order_progress_group}
              group2={order_progress_group2}
              group3={order_progress_group3}
              progressList={progressDisplayList}
              setProgressList={setProgressList}
              operations={operations}
              onDetail={onDetailCb}
              onRefresh={() => {
                setRefreshTrigger(v => {
                  return v + 1;
                });
              }}
              itemTypes={[order_progress_item_type]}
            />
          </Container>
        </div>
        <ToastContainer />
      </div>
    </Fragment>
  );
};
export default OrderProgress;
OrderProgress.propTypes = {};
