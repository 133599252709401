import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import useMobile from '../../hooks/useMobile';
import { selectBranch, selectCurrentOrg } from '../../helpers/org/orgHelper';
import { Dropdown } from 'react-bootstrap';
import urlParamSanitize from '../../helpers/url-param-sanitizer';
import { useParams } from 'react-router-dom';
import { uniq } from 'lodash';
import { getState } from '../../store';
import { toastResponseError } from '../../helpers/common/helper';
import { getStationInstances } from '../../services/items';
import { toastError } from '../../helpers/packing/packingHelper';

export const BranchSelector = memo(() => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  //const org = useSelector(state => state.user.org);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [currentBranch, setCurrentBranch] = useState(user.branch || {});
  const [isMobile] = useMobile();
  useEffect(() => {
    setBranches(user.branches || []);
    setCurrentBranch(user.branch || {});
  }, [user]);
  const onDropdownSelect = useCallback(
    branch => {
      dispatch(selectBranch(user, branch));
    },
    [dispatch, user],
  );

  const onDropdownToggle = useCallback(
    async status => {
      setDropdownOpen(status);
    },
    [setDropdownOpen],
  );

  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          isDropdownOpen ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );

  return (
    <Fragment>
      {branches && branches.length > 1 && (
        <div className='d-flex'>
          <div className='d-flex'>
            <Dropdown
              className='page-size-selector'
              onToggle={onDropdownToggle}
              flip={'false'}
              drop={'down'}
            >
              <Dropdown.Toggle
                as={CustomToggle}
                id='dropdown-custom-components'
              >
                <div
                  className={`btn-label text-primary print-text btn-primary-tapped text-button-padded`}
                >
                  <div className={'text-nowrap select-printer-text'}>
                    {currentBranch
                      ? isMobile
                        ? currentBranch.branch_name.substring(0, 1)
                        : currentBranch.branch_name
                      : isMobile
                      ? 'SB'
                      : 'Select a Branch'}
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className='overflow-auto  dropdown'
                align={{ sm: 'right' }}
              >
                {branches.map(_branch => (
                  <Dropdown.Item
                    onClick={() => onDropdownSelect(_branch)}
                    disabled={false}
                    key={_branch.branch_id}
                    eventKey={_branch.branch_id}
                    className={`printer-select-box ${
                      _branch && _branch.uuid === currentBranch.uuid
                        ? 'selected'
                        : ''
                    }`}
                  >
                    {_branch.branch_name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      )}
    </Fragment>
  );
});

/* eslint-disable sonarjs/cognitive-complexity */
const StationSelector = memo(
  ({
    history,
    location,
    currentStation,
    onResetStation,
    moduleHome,
    moduleBasePath,
    onSetCurrentStation,
    onResetCurrentStation,
    onSetBranchCurrentStation,
    skipStationCodes,
    stationCodes,
  }) => {
    const dispatch = useDispatch();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [stations, setStations] = useState([]);
    const { StationId, OrgId } = useParams();
    const uuid = useMemo(() => urlParamSanitize(StationId), [StationId]);
    const orgId = useMemo(() => urlParamSanitize(OrgId), [OrgId]);
    const [previousStation, setPreviousStation] = useState(null);

    useEffect(() => {
      if (!previousStation && !currentStation) {
        return;
      }
      if (!previousStation) {
        setPreviousStation(currentStation);
        return;
      }
      if (!currentStation && previousStation) {
        onResetStation();
        setPreviousStation(null);
        return;
      }
      if (currentStation.uuid !== previousStation.uuid) {
        setPreviousStation(currentStation);
        //syncScannedRecords(true, dispatch);
        //syncOrderItems(dispatch);
      }
    }, [
      dispatch,
      previousStation,
      currentStation,
      setPreviousStation,
      onResetStation,
    ]);
    const branch = useSelector(state => state.user.branch);
    const org = useSelector(state => state.user.org);
    const getUrl = useCallback(
      ({ station }) => {
        return `${moduleHome}/${org.uuid}/${
          station ? station.uuid : currentStation.uuid
        }`;
        //console.log(url);
      },
      [org, currentStation, moduleHome],
    );
    const onDropdownSelect = useCallback(
      station => {
        if (!station) return;
        const operations = station.operations
          ? station.operations.map(val => val.operation.operation_code)
          : [];
        const parentOperations = station.operations
          ? uniq(
              station.operations
                .map(val => val.operation.parent_operation_code)
                .filter(val => val && val.length > 0),
            )
          : [];
        const _station = {
          uuid: station.uuid,
          org_id: station.org_id,
          branch_id: station.branch_id,
          instance_name: station.instance_name,
          station_group: station.station_group,
          station_code: station.station.station_code,
          station_name: station.station.name,
          operations: operations,
          parent_operations: parentOperations,
          allow_scan_uploads: station.station.allow_scan_uploads === true,
          allow_box_scan: station.station.allow_box_scan === true,
          allow_offline_scan: station.station.allow_offline_scan === true,
          auto_operation: station.auto_operation === true,
          supported_item_type: station.supported_item_type || [],
          enable_item_print: station.enable_item_print,
        };
        onSetCurrentStation(_station);
        //dispatch(setCurrentStation(_station));
        history.push(moduleHome + '/' + org.uuid + '/' + station.uuid);
        return _station;
      },
      [org, history, moduleHome, onSetCurrentStation],
    );
    /* eslint-disable sonarjs/cognitive-complexity */
    useEffect(() => {
      const fn = async () => {
        const MODULE_ORG_HOME = moduleHome + '/' + org.uuid;
        //const _currentStation = currentStation || { branch_id: '' };
        if (location && location.pathname === moduleBasePath) {
          history.replace(MODULE_ORG_HOME);
          return;
        }
        if (location && location.pathname === moduleHome) {
          history.replace(MODULE_ORG_HOME);
          return;
        }
        if (location && !location.pathname.startsWith(moduleHome)) {
          return;
        }
        if (!org || !org.uuid) {
          return;
        }
        if (!orgId || orgId === '') {
          history.replace(MODULE_ORG_HOME);
          return;
        }
        if (org.uuid !== orgId) {
          const _org = (getState().user?.orgs || []).find(
            val => val.uuid === orgId,
          );
          if (!_org) {
            history.replace(`${MODULE_ORG_HOME} /${uuid}`);
            return;
          }
          if (_org.uuid !== orgId) {
            return;
          }
          dispatch(selectCurrentOrg(_org));
          return;
        }
        if (!branch || branch === null) {
          return;
        }
        if (currentStation && !currentStation.branch_id) {
          //dispatch(setBranchCurrentStation());
          onSetBranchCurrentStation();
          return;
        }
        if (currentStation && branch.branch_id !== currentStation.branch_id) {
          onResetCurrentStation();
          return;
        }
        if (!branch) {
          return;
        }
        if (!currentStation || !currentStation.uuid) {
          if (uuid !== null && uuid !== '' && branch && branch.branch_id) {
            const getStationResp = await getStationInstances(
              {
                branch_id: branch ? [branch.branch_id] : [],
                not_station_code: skipStationCodes || [],
                station_code: stationCodes || [],
                deleted: [false],
                active: [true],
                check_user_access: true,
              },
              0,
              100,
            );
            if (getStationResp.status !== 200) {
              toastResponseError(
                getStationResp,
                'Unable to Get the Station Detail',
              );
              return;
            }
            if (getStationResp.payload.length === 0) {
              toastError('Station Not Found');
              return;
            }
            onDropdownSelect(getStationResp.payload[0]);
            return;
          }
          return;
        }
        //Reset if Branch Changes
        if (branch.branch_id !== currentStation.branch_id) {
          //dispatch(setBranchCurrentStation());
          onSetBranchCurrentStation();
          return;
        }
        if (!currentStation || !currentStation.uuid) {
          return;
        }
        if (branch.branch_id !== currentStation.branch_id) {
          return;
        }
        if (uuid !== currentStation.uuid) {
          history.replace(
            getUrl({
              station: currentStation,
            }),
          );
        }
      };
      fn();
      //console.log(path);
    }, [
      uuid,
      orgId,
      org,
      history,
      location,
      currentStation,
      branch,
      dispatch,
      onDropdownSelect,
      getUrl,
      onResetCurrentStation,
      onSetBranchCurrentStation,
      moduleBasePath,
      moduleHome,
      skipStationCodes,
      stationCodes,
    ]);
    /* eslint-enable sonarjs/cognitive-complexity */
    const getStationResp = useCallback(
      async branch => {
        if (!branch || !branch.branch_id) {
          new Promise(resolve => {
            resolve({
              status: 200,
              payload: [],
            });
          });
        }
        return await getStationInstances(
          {
            branch_id: branch ? [branch.branch_id] : [],
            not_station_code: skipStationCodes,
            station_code: stationCodes || [],
            deleted: [false],
            active: [true],
            check_user_access: true,
          },
          0,
          100,
        );
      },
      [skipStationCodes, stationCodes],
    );
    const onDropdownToggle = useCallback(
      async status => {
        setDropdownOpen(status);
      },
      [setDropdownOpen],
    );
    const refreshStations = useRef(false);
    useEffect(() => {
      const fn = async () => {
        try {
          if (refreshStations.current) {
            return;
          }
          const stationResp = await getStationResp(branch);
          if (stationResp.status === 200) {
            setStations(stationResp.payload);
          }
          refreshStations.current = true;
        } finally {
          refreshStations.current = false;
        }
      };
      fn();
    }, [getStationResp, setStations, branch]);
    const isAutoSelected = useRef('');

    useEffect(() => {
      if (
        stations.length === 1 &&
        isAutoSelected.current !== stations[0]?.uuid &&
        !currentStation
      ) {
        onDropdownSelect(stations[0]);
        isAutoSelected.current = stations[0]?.uuid;
      }
    }, [stations, currentStation, onDropdownSelect]);

    /* eslint-disable sonarjs/no-identical-functions */
    const CustomToggle = React.forwardRef(
      (
        { children, onClick },
        ref, //eslint-disable-line
      ) => (
        <div //eslint-disable-line
          className={`title-sm cursor-pointer page-size-display ${
            isDropdownOpen ? 'active' : ''
          }`}
          ref={ref}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
        </div>
      ),
    );

    return (
      <Fragment>
        <div className='d-flex'>
          <div className='d-flex'>
            <Dropdown
              className='page-size-selector'
              onToggle={onDropdownToggle}
              flip={'false'}
              drop={'down'}
            >
              <Dropdown.Toggle
                as={CustomToggle}
                id='dropdown-custom-components'
              >
                <div
                  className={`btn-label text-primary print-text btn-primary-tapped text-button-padded`}
                >
                  <div className={'text-nowrap select-printer-text'}>
                    {currentStation
                      ? currentStation.instance_name
                      : 'Select a Station'}
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                className='overflow-auto  dropdown'
                align={{ sm: 'right' }}
              >
                {stations.map(station => (
                  <Dropdown.Item
                    onClick={() => onDropdownSelect(station)}
                    disabled={false}
                    key={station.uuid}
                    eventKey={station.uuid}
                    className={`printer-select-box ${
                      currentStation && currentStation.uuid === station.uuid
                        ? 'selected'
                        : ''
                    }`}
                  >
                    {station.instance_name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </Fragment>
    );
  },
);
/* eslint-enable sonarjs/cognitive-complexity */

StationSelector.prototype = {
  location: PropTypes.object,
  history: PropTypes.object,
};

export const StationAndBranchSelector = ({
  location,
  history,
  currentStation,
  onResetStation,
  onResetCurrentStation,
  onSetCurrentStation,
  onSetBranchCurrentStation,
  moduleHome,
  moduleBasePath,
  skipStationCodes,
  stationCodes,
  defaultLabel,
}) => {
  return (
    <Fragment>
      <div className='ps-2 d-flex align-items-center '>
        {currentStation
          ? currentStation.station_name
          : defaultLabel
          ? defaultLabel
          : ''}
      </div>

      <div className='d-flex'>
        <div className='ps-2 d-flex align-items-center '>
          <StationSelector
            history={history}
            location={location}
            currentStation={currentStation}
            onResetStation={onResetStation}
            onSetCurrentStation={onSetCurrentStation}
            onResetCurrentStation={onResetCurrentStation}
            onSetBranchCurrentStation={onSetBranchCurrentStation}
            moduleHome={moduleHome}
            moduleBasePath={moduleBasePath}
            skipStationCodes={skipStationCodes}
            stationCodes={stationCodes}
          />
        </div>
        <div className='ps-2 d-flex align-items-center'>
          <BranchSelector />
        </div>
      </div>
    </Fragment>
  );
};
